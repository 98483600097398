import React from "react";
import { Avatar, Heading, VStack } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";

const greeting = "Kel Santiago";
const bio1 = "Data-Driven Tech Leader";
const bio2 = "D/Acc - Let's Build an Equitable Future Together";

// Implement the UI for the LandingSection component according to the instructions.
// Use a combination of Avatar, Heading and VStack components.
const LandingSection = () => (
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    isDarkBackground
    backgroundColor="#2A4365"
  >
    <VStack w="1024px" p={32} alignItems="center">
      <Avatar src="./images/portfolio.jpeg" size='2xl'/>
      <Heading size='lg'>{greeting}</Heading>
      <br/>
      <Heading size='md' textAlign='center'>
        
        {bio1}
        
      </Heading>
      <p> {bio2} </p>
    </VStack>
  </FullScreenSection>
);

export default LandingSection;
