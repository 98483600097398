import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";

const projects = [
  {
    title: "Simon",
    description:
      "Currently developed with Paolo Simeti, “Simon Says” is built using Typed React and the Context Provider API for game settings. The D3 library handles SVG rendering of dynamic circles, while MaterialUI designs the UI. The project aims to deliver an engaging user experience, leveraging these technologies effectively for performance and ease of use.”",
    getImageSrc: () => "images/photo1.png",
    styling: 'width:500px;height:500px',
    link: 'http://simon.socialglass.co',
  },
  {
    title: "LettuceDCA API README.md",
    description:
      "Developed in TypeScript and tested with Jest, this API enables users to input a token, wallet address(es), network, and preferred currency to obtain an aggregated result of Dollar-Cost Averaging (DCA) calculations. It does this by using an agnostic client that interfaces with decentralized distributed system apis ",
    getImageSrc: () => "images/software.jpeg",
    link: 'http://api.lettucedca.com',
    context: {
      link: "http://api.lettucedca.com/architecture/README.md", 
      text: 'API README.md',
      post: ' for the curious mind.'
      }
  },
  {
    title: "LettuceDCA",
    description:
      "In development with Paolo Simeti, this application calculates the dollar cost average across various blockchain wallets, currently supporting XRPL and Hedera networks. This uses the API above, built using Typed React and utilizes MaterialUI for the interface. This frontend application leverages the LettuceDCA API.",
    getImageSrc: () => "images/xrplettuce.gif",
    link: 'http://dev.lettucedca.com'
  },
  {
    title: "Concentration",
    description:
      "A new web-based game currently in the research and design phase in collaboration with Paolo Simeti, expected to launch in Q1 ’25. This game is a modern spin on a classic that combines pictionary with pattern recognition mini-games. Developed using Typed React and Node.js, it utilizes MaterialUI for an engaging interface.",
    getImageSrc: () => "images/photo2.jpg",
    link: '#projects'
  },
];

const ProjectsSection = () => {
  return (
    <FullScreenSection
      backgroundColor="#000"
      isDarkBackground
      p={8}
      alignItems="flex-start"
      spacing={8}
    >
      <Heading as="h1" id="projects-section">
        Featured Projects
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2,minmax(0,1fr))"
        gridGap={8}
        sx={{
          '@media screen and (max-width: 768px)': {
            gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
          },
        }}
      >
        {projects.map((project) => (
          <Card
            key={project.title}
            style={project.styling || ''}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
            link={project.link}
            context={project.context || null}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
