import { Box, Heading, HStack, Image, Text, VStack, AspectRatio } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Card = ({ title, description, imageSrc, link, context, styling }) => {
  // Implement the UI for the Card component according to the instructions.
  // You should be able to implement the component with the elements imported above.
  // Feel free to import other UI components from Chakra UI if you wish to.
  return (
    <HStack backgroundColor="#2A4365" borderRadius="5px" maxWidth="700px">
      <VStack color="#fff">
        <Box alignItems='center' display='flex' flexDirection='column' justifyContent='flex-end'>
            <Image objectFit="cover" backgroundColor='#fff' src={imageSrc} borderBottomRightRadius="10px" borderBottomLeftRadius="10px" />
        </Box>
        <Box paddingTop='20px'>
          <Box alignItems="flex-start" padding="15px">
            <Heading fontSize='lg'>
              {title}
            </Heading>
            <Text align>
              {description}
              {context ? (<><a style={{ color: '#FFD700', textDecoration: 'underline' }} href={context.link}>{context.text}</a> {context.post} </>) : ''}
            </Text>
            <Text paddingTop="10px" >
              <a style={{ color: '#FFD700' }} href={link} target='_blank'>See more &nbsp;
                <FontAwesomeIcon icon={faArrowRight} size="1x" />
              </a>
            </Text>
          </Box>
        </Box>
      </VStack>
    </HStack>
  )
};

export default Card;
