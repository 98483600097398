import { useState } from "react";
import emailjs from 'emailjs-com'

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * This is a custom hook that can be used to submit a form and simulate an API call
 * It uses Math.random() to simulate a random success or failure, with 50% chance of each
 */
const useSubmit = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const submit = async (data) => {
    const random = Math.random();
    setLoading(true);
    const serviceID = 'portfolio';
    const templateID = 'portfolio';
    const userID = 'your_user_id';

    const templateParams = {
      to_name: 'santiagokel18@gmail.com',
      from_name: 'webappportfolio',
      message: JSON.stringify(data),
    };

    try {
      emailjs.send(serviceID, templateID, templateParams, userID)
        .then(response => console.log('Email successfully sent!', response))
      setResponse({
        type: 'success',
        message: `Thanks for your submission ${data.firstName}, I will get back to you shortly!`,
      })
    } catch (error) {
      setResponse({
        type: 'error',
        message: 'Something went wrong, please try again later!',
      })
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, response, submit };
}

export default useSubmit;
