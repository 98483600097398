import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faMedium,
  faStackOverflow,
} from "@fortawesome/free-brands-svg-icons";
import { Box, HStack } from "@chakra-ui/react";

const socials = [
  {
    icon: faEnvelope,
    url: "mailto: kelsantiago.h@gmail.com",
  },
  {
    icon: faGithub,
    url: "https://github.com/kel-3pt0",
  },
  {
    icon: faLinkedin,
    url: "https://www.linkedin.com/in/kel-santiago-15873949/",
  },
  // {
  //   icon: faMedium,
  //   url: "https://medium.com",
  // },
  // {
  //   icon: faStackOverflow,
  //   url: "https://stackoverflow.com",
  // },
];

const Header = () => {
  const header = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    if (header.current) {
    if (scrollPosition < window.pageYOffset) {
      setScrollPosition(window.pageYOffset)
      header.current.style.transform = 'translateY(-200px)'
    }
    else {
      setScrollPosition(window.pageYOffset)
      header.current.style.transform = 'translateY(-0px)'
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollPosition])
  const handleClick = (anchor) => () => {
    const id = `${anchor}-section`;
    const element = document.getElementById(id);
    console.log(`document.getElementById('${id}')`)
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Box
      zIndex={1000}
      position="fixed"
      top={0}
      left={0}
      right={0}
      translateY={0}
      transitionProperty="transform"
      transitionDuration=".3s"
      transitionTimingFunction="ease-in-out"
      backgroundColor="#18181b"
      ref={header}
    >
      <Box color="white" maxWidth="1280px" margin="0 auto">
        <HStack
          px={16}
          py={4}
          justifyContent="space-between"
          alignItems="center"
          display="grid"
        gridTemplateColumns="repeat(2,minmax(0,1fr))"
        gridGap={8}
        sx={{
          '@media screen and (max-width: 768px)': {
            gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
          },
        }}
        >
          <nav>
            {
              socials.map((social,) => {
                return <a key={social.url} href={social.url} target='_blank' style={{ margin: '0 10px' }} > <FontAwesomeIcon icon={social.icon} size='2x' /> </a>
              })
            }
          </nav>
          <nav>
            <HStack spacing={8}>
              <a href="#projects" onClick={  handleClick('projects')  }><b>Projects</b></a>
              <a href="#contact-me" onClick={ handleClick('contact-me')  }><b>Contact</b></a>
            </HStack>
          </nav>
        </HStack>
      </Box>
    </Box>
  );
};
export default Header;
